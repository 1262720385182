<template>
    <div>  
      <Toast />
      <p class="error" v-if="error">{{ error }}</p>
  
      <p class="decode-result" v-if="result">
        <b>{{ result }}</b>
      </p>
    
      <div>
        <qrcode-stream
          :constraints="selectedConstraints"
          :track="trackFunctionSelected.value"
          :formats="selectedBarcodeFormats"
          @error="onError"
          @detect="onDetect"
          @camera-on="onCameraReady"
          class="scanner"
        />
      </div>
      <div style="width: 100%; display: flex; justify-content: center;">
        <Button @click="verifyQrCode" class="verify-button" label="Verify" />
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue';
  import { QrcodeStream } from 'vue-qrcode-reader';
  import axios from 'axios';
  import { Toast } from 'primevue/toast';
  import Button from 'primevue/button';
  import { useToast } from 'primevue/usetoast';
  
  const result = ref('');
  const verificationResult = ref(null);
  const error = ref('');
  const toast = useToast();
  
  function onDetect(detectedCodes) {
    console.log(detectedCodes);
    result.value = JSON.stringify(detectedCodes.map((code) => code.rawValue));
  }
  
  async function verifyQrCode() {
    try {
      const response = await axios.post('https://api.egamena.com/v1/girls-game-gala/verify-qr', { qrUrl: result.value });
      if (response.status === 200 && response.data.exists) {
        toast.add({ severity: 'success', summary: 'Verification Successful', detail: 'User successfully verified!', life: 3000 });
      } else {
        toast.add({ severity: 'warn', summary: 'Verification Failed', detail: 'User not found in the database.', life: 3000 });
      }
      verificationResult.value = response.data.exists;
    } catch (err) {
      console.error('Error verifying QR code:', err);
      error.value = 'Error verifying QR code. Please try again.';
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error verifying QR code. Please try again.', life: 3000 });
    }
  }
  
  const selectedConstraints = ref({ facingMode: 'environment' });
  const defaultConstraintOptions = [
    { label: 'rear camera', constraints: { facingMode: 'environment' } },
    { label: 'front camera', constraints: { facingMode: 'user' } }
  ];
  const constraintOptions = ref(defaultConstraintOptions);
  
  async function onCameraReady() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(({ kind }) => kind === 'videoinput');
  
    constraintOptions.value = [
      ...defaultConstraintOptions,
      ...videoDevices.map(({ deviceId, label }) => ({
        label: `${label} (ID: ${deviceId})`,
        constraints: { deviceId }
      }))
    ];
  
    error.value = '';
  }
  
  function paintOutline(detectedCodes, ctx) {
    for (const detectedCode of detectedCodes) {
      const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;
  
      ctx.strokeStyle = 'red';
  
      ctx.beginPath();
      ctx.moveTo(firstPoint.x, firstPoint.y);
      for (const { x, y } of otherPoints) {
        ctx.lineTo(x, y);
      }
      ctx.lineTo(firstPoint.x, firstPoint.y);
      ctx.closePath();
      ctx.stroke();
    }
  }
  function paintBoundingBox(detectedCodes, ctx) {
    for (const detectedCode of detectedCodes) {
      const {
        boundingBox: { x, y, width, height }
      } = detectedCode;
  
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#007bff';
      ctx.strokeRect(x, y, width, height);
    }
  }
  function paintCenterText(detectedCodes, ctx) {
    for (const detectedCode of detectedCodes) {
      const { boundingBox, rawValue } = detectedCode;
  
      const centerX = boundingBox.x + boundingBox.width / 2;
      const centerY = boundingBox.y + boundingBox.height / 2;
  
      const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width);
  
      ctx.font = `bold ${fontSize}px sans-serif`;
      ctx.textAlign = 'center';
  
      ctx.lineWidth = 3;
      ctx.strokeStyle = '#35495e';
      ctx.strokeText(detectedCode.rawValue, centerX, centerY);
  
      ctx.fillStyle = '#5cb984';
      ctx.fillText(rawValue, centerX, centerY);
    }
  }
  const trackFunctionOptions = [
    { text: 'nothing (default)', value: undefined },
    { text: 'outline', value: paintOutline },
    { text: 'centered text', value: paintCenterText },
    { text: 'bounding box', value: paintBoundingBox }
  ];
  const trackFunctionSelected = ref(trackFunctionOptions[1]);
  
  const barcodeFormats = ref({
    qr_code: true,
  });
  const selectedBarcodeFormats = computed(() => {
    return Object.keys(barcodeFormats.value).filter((format) => barcodeFormats.value[format]);
  });
  
  function onError(err) {
    error.value = `[${err.name}]: `;
  
    if (err.name === 'NotAllowedError') {
      error.value += 'you need to grant camera access permission';
    } else if (err.name === 'NotFoundError') {
      error.value += 'no camera on this device';
    } else if (err.name === 'NotSupportedError') {
      error.value += 'secure context required (HTTPS, localhost)';
    } else if (err.name === 'NotReadableError') {
      error.value += 'is the camera already in use?';
    } else if (err.name === 'OverconstrainedError') {
      error.value += 'installed cameras are not suitable';
    } else if (err.name === 'StreamApiNotSupportedError') {
      error.value += 'Stream API is not supported in this browser';
    } else if (err.name === 'InsecureContextError') {
      error.value +=
        'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
    } else {
      error.value += err.message;
    }
  }
  </script>
  
  <style scoped>
  .error {
    font-weight: bold;
    color: red;
  }
  .barcode-format-checkbox {
    margin-right: 10px;
    white-space: nowrap;
    display: inline-block;
  }
  .qr-reader {
    text-align: center;
    margin: 20px;
  }
  .verification-result {
    margin-top: 20px;
  }
  .decode-result {
    margin-top: 20px;
    font-weight: bold;
  }
  .scanner {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  .verify-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  </style>